.btn-groups {
  width: 100%;
  border-radius: 5px;
  margin: 0;

  .yes {
    background-color: #007bff !important;
    color: white;
  }

  .no {
    background-color: #6c757d !important;
    color: white;
  }
}
