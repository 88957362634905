.activity-container {
  position: relative;
  padding: 8px 0;
  
  .divider {
    position: absolute;
    top: 24px;
    left: 20px;
    height: 100%;
    width: 1px;
    background: rgb(229 231 235);
  }

  .wrapper {
    position: relative;
    display: flex;
    align-items: flex-start;
    
    .activity-icon {
      font-size: 12px;
      width: 40px;
      height: 40px;
    }
  }

  .detail-wrapper {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgb(229 231 235);
    padding-bottom: 10px;
    width: 100%;
    margin-left: 20px;
    
    &.message {
      padding: 10px;
      background-color: #e5e7eb;
      border: none;
    }

    .detail {
      font-size: 18px;
      color: rgb(55 65 81);

      &.admin {
        background-color: rgb(0, 115, 255);
        width: fit-content;
        color: white;
      }
    }

    .date {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 400;
      font-style: italic;
      color: rgb(156 163 175);
    }

    .expendable {
      display: flex;
      flex-direction: column;
      margin-bottom: 8px;

      .expandable-details {
        div {
          font-size: 18px;
          color: rgb(55 65 81);
        }
        span {
          font-style: italic;
          color: rgb(156 163 175);
        }
      }

      .action {
        color: rgb(59 130 246);
        cursor: pointer;
        text-wrap: nowrap;
      }
    }

    .action-group {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      .btn {
        margin: 0 !important;
      }
    }
  }

  .message {
    white-space: pre-line;
    color: #292929f1;
    font-size: 16px;
    font-family: sans-serif;
  }
}

.disabled-text {
  margin-top: 8px;
  padding: 8px 0;
  font-style: italic;
  border-radius: 8px;
  width: fit-content;
}

.emoji {
  height: 32px;
  width: 32px;
}

.w-fit {
  width: fit-content;
}

.invoice {
  color: #202020;
  text-decoration: underline;
  padding: 4px 8px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
}

.requirementImages {
  gap: 12px;

  .imageBox {
    width: 12rem;
    height: 11rem;
    border-radius: 8px;
    border: 1px solid gray;
  }
}

.requirementVideo {
  gap: 12px;

  .videoBoxWrapper {
    width: 15rem;

    .box {
      height: 11rem;
      border-radius: 8px;
      border: 1px solid gray;
    }
  }
}

.font-semibold {
  font-weight: 600;
}

.activity-header {
  flex-direction: row;
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 25px;

  .actions {
    gap: 10px;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .profit-t {
    display: none;
  }
  .profit-bt {
    display: flex;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    .actions {
      flex-direction: row;
    }
    .profit-t {
      display: none;
    }
    .profit-bt {
      display: flex;
    }
  }

  @media (min-width: 650px) and (max-width: 768px) {
    .profit-t {
      display: flex;
    }
    .profit-bt {
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 992px) {
    flex-direction: column;
    .actions {
      flex-direction: row;
    }
    .profit-t {
      display: flex;
    }
    .profit-bt {
      display: none;
    }
  }

  @media (min-width: 993px) and (max-width: 1080px) {
    .actions {
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    }
    .profit-t {
      display: none;
    }
    .profit-bt {
      display: flex;
    }
  }
}

.tabs {
  display: none;
  justify-content: center;
  gap: 10px;

  @media (max-width: 768px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 992px) {
    display: flex;
  }
}

.activity-section {
  width: 48%;
  display: block;

  &.customer,
  &.vendor {
    width: 100%;
  }

  @media (max-width: 768px) {
    display: none;
  }
  
  @media (min-width: 768px) and (max-width: 992px) {
    display: none;
  }

  &.customer.show {
    display: block;
  }

  &.vendor.show {
    display: block;
  }
}