.vendorPlus {
    width: 30px;
    height: 20px;
    cursor: pointer;
    margin-right: 3px;
    background: #ececec;
    border-radius: 6px;
}

.mt-10 {
	margin-top: 10px;
}

.singleVendorTop.card {
    margin-top: 30px;
    width: 96%;
    margin-left: 20px;
}

.singleVendorText {
    font-weight: bold;
    color: #40ABC4;
    margin-left: 10px;
}

.closeEnvelope {
    color: #39AA41;
}
