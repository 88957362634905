.containerModal {
  position: relative;
  margin: auto;
  overflow: hidden;
  width: 650px;
  padding: 20px;
  /* height: 430px; */
  background: #f5f5f5;
  /* box-shadow: 5px 5px 15px rgba(186, 126, 126, 0.5); */
  /* border-radius: 10px; */
}
p {
  font-size: 0.8em;
  color: #696060;
  letter-spacing: 1px;
}
h1 {
  font-size: 1.2em;
  color: #4e4e4e;
  margin-top: -5px;
}
h2 {
  color: #5e72e4;
  margin-top: -5px;
}
.image {
  width: 100%;
  border: 8px double #fff;
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 0 0 10px #dadada;
  margin-top: 35px;
}
.slideshow-buttons {
  top: 70%;
  display: none;
}
.one,
.two,
.three,
.four {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #e0c9cb;
}
.one {
  left: 22%;
}
.two {
  left: 27%;
}
.three {
  left: 32%;
}
.four {
  left: 37%;
}
.product {
  width: 100%;
  height: 100%;
}
.inStock {
  color: #3bad45;
}
.outStock {
  color: #722f2f;
}
.desc {
  text-transform: none;
  letter-spacing: 0;
  margin-bottom: 17px;
  color: #4e4e4e;
  font-size: 0.7em;
  line-height: 1.6em;
  margin-right: 25px;
  text-align: justify;
}
button {
  padding: 10px;
  display: inline-block;
  outline: 0;
  border: 0;
  margin: -1px;
  border-radius: 2px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #66686b;
}
button:hover {
  transition: all 0.4s ease-in-out;
}
.editModal {
  width: 150px;
  height: 30px;
}
.deleteModal {
  width: 150px;
  height: 30px;
}
.sizes {
  display: grid;
  color: #d9aab7;
  grid-template-columns: repeat(auto-fill, 30px);
  width: 60%;
  grid-gap: 4px;
  margin-left: 20px;
  margin-top: 5px;
}
.sizes:hover {
  cursor: pointer;
}
.pick {
  margin-top: 11px;
  margin-bottom: 0;
  margin-left: 20px;
}
.size {
  padding: 9px;
  border: 1px solid #e0c9cb;
  font-size: 0.7em;
  text-align: center;
}
.size:hover {
  background: #ba7e7e;
  color: #f5f5f5;
  transition: all 0.4s ease-in-out;
}
.focus {
  background: #ba7e7e;
  color: #f5f5f5;
}
