.HeaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.HeaderWrapper button {
  margin: 15px;
}

.css-2b097c-container {
  width: inherit;
}

.box-container {
  gap: 12px;
  flex-wrap: wrap;

  .box-wrapper {
    position: relative;
    .item {
      position: relative;

      &:hover {
        .close {
          display: block;
        }
      }

      .close {
        display: none;
        cursor: pointer;
        position: absolute;
        top: -8px;
        right: -8px;
        background: gray;
        border-radius: 50%;
        border: 1px soild gray;
      }

      .box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 176px;
        height: 176px;
        border: 1px solid gray;
        border-radius: 1rem;
        overflow: hidden;
        cursor: pointer;
      }
    }
  }
}

.form-container {
  .btn-group {
    gap: 8px;
    align-items: center;

    .action {
      gap: 8px;
    }

    @media (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.camera-container {
  position: relative;

  .icon-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    width: fit-content;

    .switch-icon {
      width: 32px;
      height: 32px;
      color: white;
    }
  }
}

.upload-video-wrapper {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  border-radius: 1rem;
  overflow: hidden;
  width: 240px;
  height: 208px;
  cursor: pointer;

  .close {
    display: none;
    cursor: pointer;
    position: absolute;
    top: -8px;
    right: -8px;
    background: gray;
    border-radius: 50%;
    border: 1px soild gray;
  }
}
