
.ProductSetting-wrapper{
  padding: 30px 24px;
  background: #FFFFFF;
  @media(min-width: 992px) {
    width: 60% ;
    margin: auto  ;
    padding: 60px 44px;
  }
    .add-section-wrapper{
     height: 28px;
     background: rgba(39, 174, 96, 0.11);
     border-radius: 5px;
     margin-bottom: 10px;
     .add-section{
       cursor: pointer;
       font-family: Nunito Sans;
       font-style: normal;
       font-weight: normal;
       padding-top: 4px;
       font-size: 14px;
       color: #606060;
       text-align: center;
     }
   }
  .add-block-btn-wrapper{
    display: flex;
    margin-bottom: 20px;
    margin-top: 0;
    .add-block-btn{
     justify-content: end;
      width: 112px;
      padding: 6px;
      border-radius: 10px;
      background-color: #e7f6ee;
      cursor: pointer;
      font-family: Nunito Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
    }
  }

  .border-top-custom{
    background: #F4F5F7;
    transform: matrix(1, 0, 0, -1, 0, 0);
    margin-bottom: 12px;
    border-bottom: 1px solid #eeeeee;
  }

  .section-delete-wrapper{
    display: flex;
    background: rgba(96, 96, 96, 0.11);
    border-radius: 5px;
    justify-content: space-between;
    margin-bottom: 10px;
    height: 28px;
    .section-title{
    flex-grow: 1;
      text-align: center;
    }
    .section-delete{
      padding: 0 10px;
      background-color:#9a5050;
      border-radius: 5px;
      color: white;
      cursor: pointer;
    }
  }

  .enable-disable-block-wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    .target-location-wrapper{
      margin-top: 37px;
      display: flex;
      flex-direction: column;
     .target-location{
       display: flex;
       justify-content: center;
       font-family: Nunito Sans;
       font-style: normal;
       font-weight: normal;
       font-size: 14px;
       line-height: 19px;
       text-decoration-line: underline;
       color: #606060;
     }
      .include-exclude-wrapper{
        margin-top: 8px;
         display: flex;
        justify-content: space-around;
       .target-include{
         font-style: normal;
         font-weight: normal;
         background: rgba(39, 174, 96, 0.05);
         border-radius: 5px;
         width: 130px;
         padding-left: 6px;
         color:#8bd3aa;
       }
        .target-exclude{
          background: rgba(161, 77, 77, 0.05);
          border-radius: 5px;
          font-style: normal;
          font-weight: normal;
          padding-left: 6px;
          color: #cea3a3;
          width: 130px;
        }
      }
    }
    .enable-disable-block{
      display: flex;
      align-items: center;
      justify-content: center;
      .enable-disable-btn{
        margin-top: 15px;
        display: flex;
        margin-right: 42px;
        font-family: Nunito-Regular;
        padding: 2px 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #606060;
        background: rgba(96, 96, 96, 0.05);
        border-radius: 5px;
      }
    }
  }
  .option-wrapper-title{
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #606060;
    margin-bottom: 15px;
    margin-top: 10px;
  }

}

.option-box-tr{
  .option-name{
    display: flex;
    align-items: center;
    .select-icon{
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #001833;
      box-sizing: border-box;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .selected-round{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #51FBF1;
      }
    }
    .option-title{
      font-family: Nunito-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #606060;
    }
  }
  .option-cost{
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #606060;
  }
  .inc-dec-btn-container-wrapper{
    .inc-dec-btn-container{
      display: flex;
      align-items: center;
      .item-quantity{
        font-family: Nunito-Regular;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.05em;
        color: #303030;
        margin-top: 2px;
      }
      .spacing-2{
        margin-right: 3px;
        cursor: pointer;
      }
    }
  }
}

.options-container-wrapper{
  td,th{
    padding-top: 18px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom:0px;
  }
  .options-container{
    .add-option-btn {
      background-color: #f6f6f6;
      border-radius: 10px;
      font-family: Nunito Sans;
      font-size: 14px;
      line-height: 19px;
      color: #79CC95;
      width: 112px;
      padding: 6px;
      cursor: pointer;
      margin-bottom: 10px;
    }

    .price-tag{
       width: 100px;
    }
    .option-box{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      cursor: pointer;
    }
    .option-name{
      display: flex;
      align-items: center;
      .select-icon{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #001833;
        box-sizing: border-box;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .selected-round{
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #51FBF1;
        }
      }
      .option-title{
        font-family: Nunito-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #606060;
      }
    }
    .price-input{
      width: 70px;
    }
    .option-cost{
      font-family: Nunito-Regular;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
     color: #606060;
    }
    svg{
      cursor: pointer;
    }


    .description-wrapper{
      @media(max-width: 768px) {
        width: 100%;
      }
      @media(min-width: 768px) and (max-width: 992px) {
        width: 70%;
      }
      width: 50%;
      margin-top: 10px;
      font-weight: normal;
      font-family: Nunito-Regular;
      text-transform: capitalize;
    }
    .option-icon-container{
      .option-icon{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
      }

    }
  }
}