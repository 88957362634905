.bg-primary {
  background-color: "#007bff";
}
.bg-danger {
  background-color: "#dc3545";
}
.bg-success {
  background-color: "#28a745";
}
.bg-secondary {
  background-color: "#6c757d";
}
.gap-2 {
  gap: 2px;
}
.gap-3 {
  gap: 3px;
}
.gap-4 {
  gap: 4px;
}
.gap-8 {
  gap: 8px;
}
.gap-12 {
  gap: 12px;
}
.gap-16 {
  gap: 16px;
}
.gap-20 {
  gap: 20px;
}
.gap-24 {
  gap: 24px;
}
