nav#navbar-main {
    display: none;
}

.pinkBack {
    background-color: #F4545E;
    height: 75px;
}

.blueBack {
    background-color: #42A1DA;
    height: 75px;
}

.yellowBack {
    background-color: #F8DA00;
    height: 75px;
}

.seaGreenBack {
    background-color: #3CA2BB;
    height: 75px;
}

.moneyIcon {
    color: #fff;
    font-size: 24px;
    vertical-align: middle;
    margin-top: 85%;
}

.salesCards {
    margin-top: 40px;
    margin-left: 36px;
    width: 90%;
}

.second_half_text {
    text-align: center;
    padding-right: 35px;
    margin-top: 15px;
}

.sales_money {
    color: #3075A2;
}

.small-text {
	font-size: 10px
}

.sales_cards.row {
    width: 100%;
}

.userDetailCards.card {
    padding: 20px;
    font-size: 14px;
}

.single_vendor_details {
    margin-top: 30px;
    width: 96.5%;
    margin-left: 20px;
}

.orangeText {
    color: orange;
}

.greenText {
    color: #53D797;
}

.vendorButtons {
    text-align: right;
}

.vendorButtons .btn {
    background-color: #000 !important;
    color: #fff !important;
    padding: 10px;
    border-radius: 2px;
    border-color: #000;
}

span.vendorAddNewBtns {
    text-align: right;
    float: right;
}

.usrIcon {
    width: 20px;
    height: 18px;
}

.vendorAddNewBtns .btn {
    background-color: #000 !important;
    color: #fff !important;
    padding: 6px;
    border-radius: 2px;
    border-color: #000;
}