.badge-count {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  min-width: 15px;
  border-radius: 50%;
  color: white;
  font-size: 10px;
  background-color: red;
}

.notification-container {
  position: sticky;
  top: 0px;
  display: flex;
  gap: 2rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: white;
  padding: 1rem 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .section-title {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .header {
    display: flex;
    gap: 2rem;
    align-items: center;

    .switch-wrapper {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
  }
}

.notification-list {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  padding: 0 1rem;

  .wrapper {
    color: black;
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .item {
    display: flex;
    justify-content: space-between;

    .title-wrapper {
      display: flex;
      gap: 0.5rem;
      flex-direction: column;
    }

    .unread-dot {
      width: 0.8rem;
      height: 0.8rem;
      fill: rgba(0, 0, 255, 0.9);
    }
  }
}

.custom-link {
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
