.ReactModal__Overlay .ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.75) !important ;
}

.navigate-button {
  background: #00000070;
  position: absolute;
  top: 50%;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 50%;
  z-index: 50;
  transform: translateY(-50%);

  &.left {
    left: 40px;
  }
  &.right {
    right: 40px;
  }
}

.image-cover {
  max-width: 500px;
  max-height: 400px;
}

.price-tag {
  display: flex;
  gap: 10px;
  font-weight: 600;
  margin-top: 10px;

  .prices {
    display: flex;
    gap: 10px;

    .price {
      font-weight: 500;
      text-decoration: line-through;
      color: rgb(239 68 68);
    }
    .finalPrice {
      font-weight: 500;
      color: #019b5d;
    }
  }
}

.invoice-modal {
  max-width: 100% !important;
  @media screen and (min-width: 1024px) {
    max-width: 1024px !important;
  }
}

.search-section {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .search {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 60%;
    
    @media screen and (max-width: 678px) {
      width: 100%;
      flex-direction: column;
    }
    
    @media screen and (min-width: 679px) and (max-width: 992px) {
      width: 80%;
    }
  }

  .inputWrapper {
    width: 100%;
  }

  @media screen and (max-width: 678px) {
    gap: 10px;
    flex-direction: column;
  }
}
