.upload-image-wrapper {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  border: 1px solid lightgray;
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;

  .product-images {
    position: relative;

    .delete-btn {
      display: none;
      cursor: pointer;
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
      background-color: lightgray;
      border: 1px solid gray;
      border-radius: 50%;
    }

    &:hover {
      .delete-btn {
        display: flex;
      }
    }

    .preview {
      width: 150px;
      height: 150px;
      border: 1px solid rgba(0, 0, 0, 0.4);
    }
  }
}

.upload-btn-wrapper {
  display: flex;
  gap: 0.8rem;
  flex-direction: column;
  margin-top: 1rem;
  .take-pic-btn {
    cursor: pointer;
    border-radius: 0.5rem;
    background-color: lightgrey;
    color: black;
    text-transform: none;
  }
}

ul {
  &.pagination {
    flex-wrap: wrap;
    row-gap: 10px;
  }
}

.new-product-card-main {
  min-width: 125px;
  max-width: 125px;
  width: 100%;
  margin-bottom: 15px;

  .product-content-wrapper {
    .product-container {
      width: 120px;

      .media {
        min-width: 120px;
        max-width: 120px;
        min-height: 120px;
        max-height: 120px;
        border-radius: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.5);
        overflow: hidden;

        &.preview {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          min-height: 100%;
          max-height: 100%;
        }
      }

      &.preview {
        width: 100%;
      }
    }

    .slider-image-select-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      width: 100%;
      .active-image-btn {
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 25px;
        max-width: 25px;
        min-height: 25px;
        max-height: 25px;
        border-radius: 6px;
        border: 0.1px solid #a22a2a;
        padding: 1px;
        box-shadow: 0 0 2px #a22a2a;
        cursor: pointer;

        &.preview {
          min-width: 30px;
          max-width: 30px;
          min-height: 30px;
          max-height: 30px;
        }
      }

      .product-video-wrapper {
        position: relative;
        width: 100%;
        overflow: hidden;
        min-width: 25px;
        max-width: 25px;
        min-height: 25px;
        max-height: 25px;

        .play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &.preview {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}

.preview {
  .carousel-control-prev {
    top: 50%;
    left: 30px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transform: translateY(-50%);
    background: rgba(102, 102, 102, 0.5);
    box-shadow: 0px -1px 15px -3px #ffffff, 0px 1px 20px 2px #ffffff;
  }
  .carousel-control-next {
    top: 50%;
    right: 30px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transform: translateY(-50%);
    background: rgba(102, 102, 102, 0.5);
    box-shadow: 0px -1px 15px -3px #ffffff, 0px 1px 20px 2px #ffffff;
  }
}
