.HeaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px;
}
.HeaderWrapper button {
  margin: 15px;
}
.selectorField {
  margin: 10px;
  padding: 10px;
}

.coupon-search {
  width: 60%;
}

@media (max-width: 576px) {
  .coupon-search {
    width: 100%;
  }
}