.selectorField {
  border-radius: 10px;
  margin-bottom: 5px;
  padding-left: 10px;
  margin-left: 500px;
}

.save-cancel-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.save-cancel-wrapper .btn {
  width: 100%;
}

select.category {
  color: #9e9e9e;
}

select.category option:not(:first-of-type) {
  color: black;
}

@media (max-width: 678px) {
  .save-cancel-wrapper {
    flex-direction: column;
  }
}

.article-content.main {
  width: 65%;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.article-content .header {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.article-content .name {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.25;
  color: #212529;
  margin-bottom: 3rem;
}

@media (max-width: 480px) {
  .article-content.main {
    width: 100%;
  }

  .article-content .name {
    font-size: 2rem;
  }
}

@media (max-width: 992px) {
  .article-content.main {
    width: 100%;
  }
}

.article-content .poster-image {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.article-content .poster-image img {
  border-radius: 0.75rem;
}

.article-content .media-image {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.article-content .media-image img {
  border-radius: 0.75rem;
}

.article-content .media-title {
  font-weight: 500;
  font-size: 1.5rem;
  color: #212529;
}

.article-content .video-wrapper {
  margin-top: 3rem;
}

.article-content .video-wrapper .article-video iframe {
  overflow: hidden;
  border-radius: 0.75rem;
  margin-bottom: 3rem;
}

.article-content .video-title {
  font-weight: 500;
  font-size: 1.5rem;
  color: #212529;
}

.comment-container {
  .comment-wrapper {
    gap: 12px;
    border-top: 2px solid rgb(208, 207, 207);
    padding-top: 12px;

    .item-wrapper {
      gap: 12px;

      .details-wrapper {
        gap: 8px;

        .profile {
          border-radius: 50%;
          height: 40px;
          width: 40px;
        }

        .name {
          font-size: 18px;
          font-weight: 600;
          margin: 0;
        }

        .date {
          gap: 4px;

          .dot {
            height: 3px;
            width: 3px;
            background: black;
            border-radius: 100%;
          }
        }
      }

      .message {
        font-weight: 500;
        font-size: 18px;
        white-space: pre-line;
      }
    }
  }
}

.article-btn {
  position: relative;
  padding: 10px 20px;
  background-color: #e8e6e6;
  color: #202020;
  border: none;
  outline: none;
  cursor: pointer;
}

.article-btn.unpublish, .article-btn.unpublish:hover {
  box-shadow: inset -12px 0 0 0 #34ed18;
  background-color: #e8e6e6;
  color: #202020;
}

.article-btn.publish, .article-btn.publish:hover {
  box-shadow: inset -12px 0 0 0 #ed1818;
  background-color: #e8e6e6;
  color: #202020;
}

