.filePreviewContainer {
  position: relative;
  width: 15rem;
  border: 1px solid #4b5563;
  border-radius: 0.5rem;
  cursor: pointer;
  overflow: hidden;

  .fileImage {
    height: 11rem;
  }

  .withoutImageIndicator {
    position: absolute;
    width: 0.375rem;
    height: 100%;
    background-color: #019b5d;
  }

  .fileDetails {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.5rem 0.5rem 0.75rem;

    .fileName {
      width: 10rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .downloadIcon {
      stroke-width: 1;
      stroke: #019b5d;
    }
  }
}
