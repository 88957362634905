.SearchBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
  margin-right: 0;
}

.passwordCheckmark {
  width: 30px;
  height: 20px;
  cursor: pointer;
  margin-right: 3px;
  border-radius: 6px;
}
