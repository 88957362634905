.flex-direction-custom{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 0;
}
.flex-direction-custom>label{
}
.flex-direction-custom>input{
    width: 100%;
    cursor: pointer;
}
.customized-view-timer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.customized-view-timer>input{
    width: 25%;
}















@media (max-width: 768px) {
    .flex-direction-custom{
        display: block;
        align-items: start;
        justify-content: space-between;
        padding-left: 0;
        padding-right: 0;
    }
    .flex-direction-custom>label{
        margin-left: 0;
    }
    .flex-direction-custom>input{
        display: inline-block;
        margin-right: 0;
        width: 25%;
    }
    .customized-view-timer{
        display: block;
        padding: 5px;
    }
    .customized-view-timer>input{
        width: 100%;
        margin-bottom: 10px;
    }

}
