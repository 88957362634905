.HeaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.HeaderWrapper button {
  margin: 15px;
}

.tickets {
  display: flex;
  position: relative;
  place-content: center;
  background-color: #d4d4d4;
}