.footerMenu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  .item {
    padding: 0 10px;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
}
