.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 300;
  background: rgba(0, 0, 0, 0.5);
}

.cover-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 300;
  background: rgba(0, 0, 0, 0.5);
}
